const keyValueList = `<div class="avoid-pagebreak section vertical-align-top<% if (CSSClasses) { for (let j = 0; j < CSSClasses.length; j++) { print(' ' + CSSClasses[j]) } } %>">
<h3><% if (title !== undefined) { print(title) } %></h3>
<table class="key-value-list">

  <% for (let i = 0; i < keyValueListData.length; i++) { %>
  
    <tr>
    
      <td><%- keyValueListData[i].label %></td>
      <td><%- keyValueListData[i].displayValue %></td>
    
    </tr>
    
  <% } %>

</table>
</div>`;

export default keyValueList;
