const detailTable = `<div class="avoid-pagebreak section vertical-align-top<% if (CSSClasses) { for (let j = 0; j < CSSClasses.length; j++) { print(' ' + CSSClasses[j]) } } %>">
<h3><% if (title !== undefined) { print(title) } %></h3>
<table class="detailTable" width="100%">
<thead>
<tr>

  <% for (let i = 0; i < columns.length; i++) { %>
    <th class="<%- 'align-' + columns[i].alignment %>"><%- columns[i].label %></th>
  <% } %>
  
</tr>
</thead>
<tbody>

  <% for (let i = 0; i < tableData.length; i++) { %>
  
    <tr>
    
      <% for(let j = 0; j < tableData[i].length; j++) { %>
        <td class="<%- 'align-' + columns[j].alignment %>"><%- tableData[i][j] %></td>
      <% } %>
    
    </tr>
    
  <% } %>

</tbody>
</table>
</div>`;

export default detailTable;
