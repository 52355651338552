import template from 'lodash/template'; // compare https://lodash.com/docs#template
import each from 'lodash/each';
import endsWith from 'lodash/endsWith';
import get from 'lodash/get';
import axios from 'axios';

import documentContainer from './templates/documentContainer';
import reportHeader from './templates/report/reportHeader';
import stakeholder from './templates/components/stakeholder';
import detailTable from "./templates/components/detailTable";
import keyValueList from "./templates/components/keyValueList";
import paragraph from "./templates/components/paragraph";
import clear from "./templates/components/clear";
import reportFooter from './templates/report/reportFooter';

const displayComponents = {
  reportHeader: reportHeader,
  reportFooter: reportFooter,
  stakeholder: stakeholder,
  detailTable: detailTable,
  keyValueList: keyValueList,
  paragraph: paragraph,
  clear: clear
}

const fallback = {
  fileName: 'DutyPay_document',
  documentTitle: 'DutyPay document',
  fileExtension: 'pdf'
}

class DocumentComposer {

  constructor() {
    this.data = {};
    this.options = {};
  }

  getHTML() {
    return this.generateHTMLDocument();
  }

  get getFileName() {
    if (endsWith(this.options.fileName, `.${this.options.fileExtension}`))
      return this.options.fileName
    else
      return this.options.fileName + '.' + this.options.fileExtension
  }

  downloadFile(file) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file)
      return
    }

    const data = window.URL.createObjectURL(file);
    let link = document.createElement('a');
    link.href = data;
    link.setAttribute('target', '_blank');
    link.download = this.getFileName;
    link.click();

    setTimeout(function () {
      window.URL.revokeObjectURL(data)
    }, 100)
  }

  createNewDocument(data, options) {
    this.data = data || {};
    this.options = options || {};

    this.options.fileExtension = get(options, 'fileExtension', fallback.fileExtension);
    this.options.fileName = get(options, 'fileName', fallback.fileName);
    this.options.documentTitle = get(options, 'documentTitle', fallback.documentTitle);
  }

  async requestPDFDocument() {
    return axios({
      method: 'post',
      url: process.env.VUE_APP_API_DEDICATEDDUTYPAYHTML2PDFADVANCED,
      data: this.generateHTMLDocument(),
      "responseType": "blob"
    });
  }

  compileHTML(templateSource, data) {
    return template(
      `${templateSource}`,
      {
        'imports': data
      });
  }

  generateHTMLDocument() {
    let compileHTML = this.compileHTML,
      documentBody = '';
    each(this.data, function (component) {
      let compiledComponent = compileHTML(displayComponents[component.type], component.data);
      documentBody += compiledComponent();
    });

    let document = this.compileHTML(`${documentContainer}`, {documentBody, ...this.options});
    return document();
  }

}

export default DocumentComposer;
