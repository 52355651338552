const stakeholder = `<div class="avoid-pagebreak section vertical-align-top">
  <% for (let i = 0; i < stakeholder.length; i++) { %>
  
    <div class="<% if (i % 2 === 0) { %>float-left<% } else { %>float-right<% } %>" style="max-width: 50%; margin-bottom: 1em;">
    
      <% for(let line = 0; line < stakeholder[i].length; line++) { %>
        <span style="display: block;"><%- stakeholder[i][line] %></span>
      <% } %>
    
    </div>
    
    <% if (i % 2 !== 0) { %><div class="clear-both"></div><% } %>
  
  <% } %>
  
  <% if (stakeholder.length % 2 !== 0) { %><div class="clear-both"></div><% } %>
</div>`;

export default stakeholder;
