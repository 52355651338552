const documentContainer = `<!DOCTYPE html>
<html lang="en">
<head>
  <style type="text/css">
    /* --------------------------------- */
    /* DOCUMENT */
    /* --------------------------------- */

    @page {
      size: A4 portrait;
      margin-top: 5mm;
      margin-bottom: 15mm;
      margin-left: 15mm;
      margin-right: 10mm;

      @top-center {
        content: element(top-center);
        padding: 0;
      }

      @bottom-left {
        font-family: 'IBM Plex Sans', 'IBM Plex Sans Arabic', 'IBM Plex Sans Hebrew', 'IBM Plex Sans KR', 'IBM Plex Sans Thai', 'IBM Plex Sans Thai Looped', 'Mada', 'Noto Sans CJK SC Regular', 'Noto Sans CJK TC Regular', 'Assistant', 'Helvetica 65 Medium', 'Helvetica 55 Roman', 'Open Sans', serif;
        content: element(bottom-left) ' ';
        padding: 0;
        font-size: 7pt;
      }

      @bottom-right {
        font-family: 'IBM Plex Sans', 'IBM Plex Sans Arabic', 'IBM Plex Sans Hebrew', 'IBM Plex Sans KR', 'IBM Plex Sans Thai', 'IBM Plex Sans Thai Looped', 'Mada', 'Noto Sans CJK SC Regular', 'Noto Sans CJK TC Regular', 'Assistant', 'Helvetica 65 Medium', 'Helvetica 55 Roman', 'Open Sans', serif;
        content: counter(page) ' / ' counter(pages) element(bottom-right);
        padding: 0;
        font-size: 7pt;
      }

    }

    html,body {
      margin: 0;
      border: none;
      padding: 0;
    }

    #top-center {
      position: running(top-center);
      width: 100%;
      max-width: 100%;
      margin-top: 10pt;
    }

    #bottom-left {
      position: running(bottom-left);
      width: 100%;
      max-width: 100%;
      font-size: 9px;
    }

    #bottom-right {
      position: running(bottom-right);
      width: 100%;
      max-width: 100%;
      font-size: 9px;
      text-align: right;
    }


    /* --------------------------------- */
    /* TYPOGRAPHY */
    /* --------------------------------- */

    * {
      font-family: 'IBM Plex Sans', 'IBM Plex Sans Arabic', 'IBM Plex Sans Hebrew', 'IBM Plex Sans KR', 'IBM Plex Sans Thai', 'IBM Plex Sans Thai Looped', 'Mada', 'Noto Sans CJK SC Regular', 'Noto Sans CJK TC Regular', 'Assistant', 'Helvetica 65 Medium', 'Helvetica 55 Roman', 'Open Sans', serif;
      color: #000000;
    }

    html, body {
      font-size: 11px;
      line-height: 14px;
    }

    h1, h2, h3, h4, h5 {
      margin: 0;
      padding: 0;
    }

    h1 {
      font-size: 28px;
      line-height: 31px;
      font-weight: 700;
      margin: 0.1em 0;
    }
    h2 {
      font-size: 22px;
      line-height: 25px;
      font-weight: 700;
      margin: 0.1em 0;
    }
    h3 {
      font-size: 18px;
      line-height: 21px;
      margin: 1em 0 .5em 0;
    }
    h4 {
      font-size: 16px;
      line-height: 19px;
      margin: 1em 0 0 0;
    }
    h5 {
      font-size: 14px;
      line-height: 17px;
      margin: 1em 0 0 0;
    }
    
    h2, h3, h4, h5 {
      font-weight: 600;
    }
    h1, h2, h3 {
      letter-spacing: -.7px;
    }

    p {
      margin: .8em 0;
      orphans: 3;
    }

    hr {
      border: 0;
      margin: 0.5em 0;
      height: 2px;
      background-color: #EBEEF5;
    }
    
    .grey {
      color: #a3a3a3;
    }


    /* --------------------------------- */
    /* HELPER CLASSES */
    /* --------------------------------- */

    .section {
      margin: .5em 0;
      padding: 0.3em 0;
      /*border-top: 1px solid #dddddd;*/
    }
    /*.content.padding-right {*/
    /*  padding: 1em 1em 0 1em;*/
    /*}*/

    /*.border-left {*/
    /*  border-left-color: rgba(0,0,0,0.33);*/
    /*  border-left-style: solid;*/
    /*  border-left-width: 2px;*/
    /*}*/

    .pagebreak-after {
      clear: both;
      page-break-after: always;
    }

    .pagebreak-before {
      clear: both;
      page-break-before: always;
    }

    .avoid-pagebreak {
      page-break-inside: avoid;
    }

    .inline-block {
      display: inline-block;
    }

    .float-left {
      display: inline-block;
      float: left;
    }

    .float-right {
      display: inline-block;
      float: right;
    }

    .clear-both {
      clear: both;
    }
    
    .clear-left {
      clear: left;
    }
    
    .clear-right {
      clear: right;
    }
    
    .align-left {
      text-align: left;
    }
    
    .align-center {
      text-align: center;
    }
    
    .align-right {
      text-align: right;
    }
    
    .vertical-align-top {
      vertical-align: top;
    }
    
    .width-half {
      width: 50%;
    }
    .width-third {
      width: 33%;
    }
    
    .min-width-half {
      min-width: 50%;
    }
    .min-width-third {
      min-width: 33%;
    }
    
    .max-width-half {
      max-width: 50%;
    }
    .max-width-third {
      max-width: 33%;
    }
    
    .padding-right {
      padding-right: 2em;
    }
    .padding-left {
      padding-left: 2em;
    }
    
    
    /* --------------------------------- */
    /* VALUES LIST */
    /* --------------------------------- */

    /*ul.key-value-list {*/
    /*  list-style: none;*/
    /*  padding: 0;*/
    /*  margin: 0;*/
    /*}*/
    /*ul.key-value-list li {*/
    /*  display: inline-block;*/
    /*  margin-right: 2em;*/
    /*  zoom:1;*/
    /*  display:inline;*/
    /*}*/
    /*ul.key-value-list li > span.key {*/
    /*  font-weight: bold;*/
    /*}*/
    /*ul.key-value-list li > span.translation {*/
    /*  padding-left: .5em;*/
    /*}*/
    /*ul.key-value-list li > span.value {*/
    /*  display: block;*/
    /*}*/


    /* --------------------------------- */
    /* DETAIL TABLE */
    /* --------------------------------- */
    
    table {
      border: none;
      border-spacing: 0;
    }
    
    table.full-width {
      width: 100%;
    }
    
    table thead tr th {
      font-weight: bold;
      border-bottom: 2px solid #ccc;
      border-top: 2px solid #ccc;
    }
    
    table tbody tr td {
      border-bottom: 1px solid #ccc;
    }
    table.no-border tbody tr td {
      border-bottom: none;
    }
    
    table thead tr th,
    table tbody tr td {
      padding: 0.4em 0.8em;
      vertical-align: text-top;
    }
    table.no-padding tbody tr td {
      padding: 0;
    }
    

    /* --------------------------------- */
    /* KEY VALUE LIST */
    /* --------------------------------- */
    
    table.key-value-list tr td {
      border-bottom: 0;
      padding: 0.2em 0.8em;
      vertical-align: text-top;
    }
    
    table.key-value-list tr td:first-child {
      font-weight: bold;
      padding-left: 0;
    }
    
    

  </style>
  <title><%- documentTitle %></title>
</head>
<body>
<%= documentBody %>
</body>
</html>`;

export default documentContainer;
